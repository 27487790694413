class OauthActionForm {
  constructor(element) {
    this.form = element;
  }

  attachEvents() {
    const _this = this;

    this.form.on('click', '[data-delete]', (e) => {
      e.preventDefault();
      this.removeBlock(e.target)
    });

    this.form.find('[data-add]').on('click', function(e) {
      e.preventDefault();
      _this.addBlock($(this));
    });

    this.form.on('keyup', '[data-options-url] :input', this.toggleDisabledOptions);
    this.toggleDisabledOptions();
  }

  addBlock(element) {
    const $el = $(element);
    const url = $el.data('endpoint');
    const type = $el.data('type');

    const $container = this.form.find(`[data-block-container="${type}"]`);
    const index = this.maximumIndex($container) + 1;

    $.ajax({
      url: url,
      method: 'POST',
      data: { index: index },
      success: function(data) {
        $container.append(data);
        const $block = $container.find(`[data-block="${index}"]`);
        $('html, body').animate({
          scrollTop: $block.offset().top
        }, 100);
      }
    })
  }

  removeBlock(element) {
    $(element).parents('[data-block]').empty().remove()
  }

  maximumIndex(blockContainer) {
    const indexes = $(blockContainer).find('[data-block]').toArray().map((block) => {
      return parseInt($(block).data('block'));
    });
    if (indexes.length === 0) { return 0; }

    return Math.max.apply(null, indexes)
  }

  toggleDisabledOptions() {
    const $input = $('[data-options-url] :input');
    if (!$input.length) { return; }
    $('[data-block-container="option"] .card').toggleClass('bg-light', $input.val().length > 0);
  }
}

export default OauthActionForm